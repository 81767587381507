<template>
  <div class="bank">
    <headTop :currentrStr="currentrStr" sfType='家长'></headTop>
    <div class="common-width bank-all">
      <a-modal
        title="创建账号"
        :visible="visible"
        @ok="onSubmitReg"
        okText="创建"
        cancelText="取消"
        @cancel="handleCancel"
      >
        <a-form-model
          class="reg"
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            ref="name"
            label="用户名"
            prop="name"
            :labelCol="{ span: 24, offset: 0 }"
            labelAlign="left"
          >
            <a-input placeholder="请输入您的用户名" v-model="form.name">
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            label="密码"
            prop="pass"
            ref="pass"
            :labelCol="{ span: 4, offset: 0 }"
            labelAlign="left"
          >
            <a-input-password placeholder="请输入您的密码" v-model="form.pass">
            </a-input-password>
          </a-form-model-item>
          <a-form-model-item
            ref="realName"
            label="真实姓名"
            prop="realName"
            :labelCol="{ span: 24, offset: 0 }"
            labelAlign="left"
          >
            <a-input
              placeholder="请输入您的真实姓名"
              v-model="form.realName"
              id="realName"
            >
            </a-input>
          </a-form-model-item>

          <a-form-model-item
            ref="sex"
            label="性别"
            prop="sex"
            :labelCol="{ span: 24, offset: 0 }"
          >
            <a-select v-model="form.sex" placeholder="请选择您的性别">
              <a-select-option value="男"> 男 </a-select-option>
              <a-select-option value="女"> 女 </a-select-option>
            </a-select>
          </a-form-model-item>
          <!-- <a-form-model-item
      ref="type"
      label="证件类型"
      prop="type"
      :labelCol="{ span: 24, offset: 0 }"
    >
      <a-select v-model="form.type" placeholder="请选择您的证件类型">
        <a-select-option value="居民身份证"> 居民身份证 </a-select-option>
        <a-select-option value="港澳台身份证"> 港澳台身份证 </a-select-option>
        <a-select-option value="护照"> 护照 </a-select-option>
        <a-select-option value="回乡证"> 回乡证 </a-select-option>
        <a-select-option value="其他"> 其他 </a-select-option>
      </a-select>
    </a-form-model-item>

    <a-form-model-item
      ref="card"
      label="证件号"
      prop="card"
      :labelCol="{ span: 24, offset: 0 }"
      labelAlign="left"
    >
      <a-input placeholder="请输入您的证件号" v-model="form.card">
        <a-icon slot="prefix" type="credit-card"
      /></a-input>
    </a-form-model-item> -->
          <a-form-model-item
            label="生日"
            ref="date"
            prop="date"
            :labelCol="{ span: 24, offset: 0 }"
          >
            <a-date-picker
              v-model="form.date"
              type="date"
              placeholder="请选择您的生日"
              style="width: 100%"
            />
          </a-form-model-item>
          <a-form-model-item
            ref="email"
            label="邮箱"
            prop="email"
            :labelCol="{ span: 24, offset: 0 }"
            labelAlign="left"
          >
            <a-input placeholder="请输入您的邮箱" v-model="form.email">
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            ref="school"
            label="学校"
            prop="school"
            :labelCol="{ span: 24, offset: 0 }"
            labelAlign="left"
          >
            <a-input placeholder="请输入您的学校" v-model="form.school">
            </a-input>
          </a-form-model-item>
          <!-- <a-form-model-item>
            <a-button type="primary" @click="onSubmitReg" style="width: 100%">
              下一步
            </a-button>
          </a-form-model-item> -->
        </a-form-model>
      </a-modal>
      <p class="bank-title"></p>
      <a-button type="danger" @click="showModal" style="float:right;margin-top:-35px;margin-bottom:10px;" > 创建新账号</a-button>
      <a-button type="primary" @click="showModalY" style="float:right;margin-top:-35px;margin-bottom:10px;margin-right:10px;" > 关联已有账号</a-button>
      <a-modal
        title="关联已有账号"
        :visible="visibleY"
        @ok="onSubmitRegY"
        okText="关联"
        cancelText="取消"
        @cancel="handleCancel"
      >
        <a-form-model
          class="reg"
          ref="ruleFormY"
          :model="formY"
          :rules="rulesY"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
        >
          <a-form-model-item
            ref="name"
            label="用户名"
            prop="name"
            :labelCol="{ span: 24, offset: 0 }"
            labelAlign="left"
          >
            <a-input placeholder="请输入您的用户名" v-model="formY.name">
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            label="密码"
            prop="pass"
            ref="pass"
            :labelCol="{ span: 4, offset: 0 }"
            labelAlign="left"
          >
            <a-input-password placeholder="请输入您的密码" v-model="formY.pass">
            </a-input-password>
          </a-form-model-item>
        
          <!-- <a-form-model-item>
            <a-button type="primary" @click="onSubmitReg" style="width: 100%">
              下一步
            </a-button>
          </a-form-model-item> -->
        </a-form-model>
      </a-modal>
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="username" label="用户名"> </el-table-column>
        <el-table-column prop="name" label="姓名"> </el-table-column>
        <el-table-column prop="phone" label="手机号"> </el-table-column>
     
      </el-table>
    </div>
  </div>
</template>
  <script>
import headTop from "../components/header.vue";
import moment from "moment";
export default {
  components: { headTop },
  data() {
    return {
      currentrStr: ["bank"],
      visible: false,
      visibleY: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 24 },
      form: {
        // name: "",
        // pass: "",
        // code: "",
        // phone: "",
        name: "",
        email: "",
        school: "",

        sex: undefined,
        type: undefined,
        card: "",
        date: "",
        realName: "",
        pass: "",
      },
      tableData: [],
      rules: {
        school: [
          // {
          //     required: true,
          //     message: "请输入您的学校",
          //     trigger: "blur",
          //   },
        ],
        sex: [
          { required: true, message: "请选择您的性别", trigger: "change" },
          {
            validator: this.checkSex,
            trigger: "change",
          },
        ],
        type: [
          { required: true, message: "请选择您的证件类型", trigger: "change" },
          {
            validator: this.checkType,
            trigger: "change",
          },
        ],
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
        card: [
          { required: true, message: "请输入您的证件号", trigger: "blur" },
          {
            validator: this.checkCard,
            trigger: "blur",
          },
        ],
        date: [
          { required: true, message: "请选择您的生日", trigger: "change" },
        ],
        name: [
          {
            required: true,
            message: "请输入您的用户名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "请输入2-20个字符",
            trigger: "blur",
          },
          {
            validator: this.userName,
            trigger: "blur",
          },
        ],
        realName: [
          {
            required: true,
            message: "请输入您的真实姓名",
            trigger: "blur",
          },
          {
            min: 1,
            max: 20,
            message: "请输入1-20个字符",
            trigger: "blur",
          },
          {
            validator: this.checkName,
            trigger: "blur",
          },
        ],
        email: [
          // {
          //     required: true,
          //     message: "请输入您的邮箱",
          //     trigger: "blur",
          //   },
          {
            validator: this.checkEmail,
            trigger: "blur",
          },
        ],
      },
      formY:{
        pass:'',
        name:''
      },
      rulesY:{
        pass: [
          {
            required: true,
            message: "请输入您的密码",
            trigger: "blur",
          },
          {
            min: 3,
            max: 20,
            message: "请输入3-20个字符",
            trigger: "blur",
          },
        ],
      
        name: [
          {
            required: true,
            message: "请输入您的用户名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 20,
            message: "请输入2-20个字符",
            trigger: "blur",
          },
        ]
      } 
    };
  },
  mounted() {

    if( window.localStorage.getItem('anjing-token-group') !='parents'){
      this.$router.push({
        path: "./index",
      });
    }
    this.initList();
  },
  methods: {
    initList() {
      let data = {
        op: "get_child_acc",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/parents/index.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.tableData = response.data.data
          } else {
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showModal() {
      this.visible = true;
    },
    showModalY(){
        this.visibleY = true;  
    },
    checkSex(rule, value, callback) {
      // 如果证件类型不为空且是居民身份证且证件号不为空的情况下校验 性别和证件号是否一致
      if (
        this.form.type != undefined &&
        this.form.type == "居民身份证" &&
        this.card != ""
      ) {
        if (
          (this.form.card.substr(16, 1) % 2 == 1 && this.form.sex == "女") ||
          (this.form.card.substr(16, 1) % 2 == 0 && this.form.sex == "男")
        ) {
          callback(new Error("身份证号与性别不一致"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    },
    userName(rule, value, callback) {
      let data = {
        op: "checkeusername",
        username: this.form.name,
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            callback();
          } else {
            callback(new Error(response.data.msg));
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    checkGrade(rule, value, callback) {
      let structRight = RegExp(
        /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$/
      ).test(value);
      if (!structRight) {
        callback(new Error("请填写正确的手机号"));
 
      } else {
        callback();
      }
    },
    checkName(rule, value, callback) {
      // 居民身份证== 中文
      let structRight = RegExp(/^[\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/).test(
        value
      );
      // 非居民身份证==中文+英文
      let structLeft = RegExp(/^[A-Za-z]*(\s[A-Za-z]*)*$/).test(value);
      if (!(structRight || structLeft) && this.form.type == undefined) {
        callback(new Error("姓名不允许是数字、特殊符号、中英文结合"));
      } else {
        callback();
      }
    },
    checkEmail(rule, value, callback) {
      console.log(value);
      if (value != "") {
        let structRight = RegExp(
          /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/
        ).test(value);
        if (!structRight) {
          callback(new Error("请填写正确的邮箱"));
          // this.codeBtn = true;
          // this.phoneCode = true;
        } else {
          let data = {
            op: "checkemail",
            email: this.form.email,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/new_registration.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                callback();
              } else {
                callback(new Error(response.data.msg));
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      } else {
        callback();
      }
    },
    onSubmitReg() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //create_child_acc
          console.log("ok");
          let data = {
            op: "create_child_acc",
            username: this.form.name,
            password: this.form.pass,
            name: this.form.realName,
            sex: this.form.sex,
            birthday: moment(this.form.date).format("YYYY-MM-DD"),
            email: this.form.email,
            school: this.form.school,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/parents/index.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.initList();
                _this.$message.info(response.data.msg);
                _this.visible=false;
              
              } else {
                _this.$message.info(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    onSubmitRegY() {
      this.$refs.ruleFormY.validate((valid) => {
        if (valid) {
          //create_child_acc
          console.log("ok");
          let data = {
            op: "bind_child_acc",
            username: this.formY.name,
            password: this.formY.pass,
          };
          let _this = this;
          this.$ajax
            .post(this.url + "/parents/index.do", _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.initList();
                _this.$message.info(response.data.msg);
                _this.visibleY=false;
              _this
              } else {
                _this.$message.info(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    handleCancel() {
      this.visible = false;
      this.visibleY = false;
    },

    // changeFun(){
    //     console.log(1)
    //     this.$confirm('确认关闭？')
    //       .then(_ => {
    //         done();
    //       })
    //       .catch(_ => {});
    //   }
    
  },
};
</script>
  <style scoped>
.bank-all {
  margin-top: 30px !important;
  text-align: left;
}
/deep/ .ant-modal {
  top: 48px;
}
.bank-title{
    height: 20px;
    width: 100%;
    /* background-image: linear-gradient(to right , #0585ce, #ffffff); */
    /* border-left: 2px solid #001f48; */
    /* color: #fff; */
    line-height: 20px;
    font-size: 14px;
    padding-left: 20px;
    border-radius: 2px;
}
</style>
  