<template>
  <div>
    <div v-if="screenwidth > 900">
      <div class="header">
        <div class="left">
          <div class="left-name" @click="look('./index')">
            <img
              src="https://www.xuezhiqiao.com/images/cgicon.png"
              width="50px"
            />
          </div>
          <div class="header-top">
            <a-menu v-model="current" mode="horizontal" @click="handleClick">
              <a-menu-item key="index" @click="look('./index', true)">
                首页
              </a-menu-item>
              <!-- <a-menu-item key="detail"> 个人信息 </a-menu-item> -->
              <a-menu-item key="bank" @click="look('./bank', true)" v-if="sfType=='家长'">
                关联账号管理
              </a-menu-item>
              <a-menu-item key="classes" @click="look('./classes', true)" v-if="sfType=='老师'">
              班级管理
              </a-menu-item>
              <a-menu-item key="grade" @click="look('./grade', true)" v-if="sfType=='学生'">
              我的班级
              </a-menu-item>

              <!-- <a-menu-item key="enroll" @click="look('./enroll', true)">
                我的报名
              </a-menu-item>
              <a-menu-item key="selfDetail" @click="look('./selfDetail', true)">
                个人信息
              </a-menu-item> -->
            </a-menu>
          </div>
        </div>
        <div class="header-right">
          <!-- <span style="margin-right: 30px; margin-top: 6px" @click="look('/')"
            >查看更多比赛</span
          > -->
          <a-button type="primary" v-if="!loginOk" @click="login">
            登录
          </a-button>
          <!-- <a-tooltip placement="bottom">
            <template slot="title">
              <p @click="out" style="cursor: pointer">退出登录</p>
            </template>

            <p
              v-if="loginOk"
              style="
                font-size: 16px;
                font-weight: bold;
                color: rgb(75 75 75);
                margin-top: 4px;
                margin-bottom: 0;
              "
            >
              欢迎回来！{{ user }}
            </p>
          </a-tooltip> -->
          <a-popover title="" placement="bottom">
            <template slot="content">
              <p @click="out" style="cursor: pointer">退出登录</p>
            </template>

            <p
              v-if="loginOk"
              style="font-size: 16px;
                font-weight: bold;
                color: rgb(75 75 75);
                margin-top: 4px;
                margin-bottom: 0;
              "
            >
            {{sf}}  欢迎回来！{{ user }}
            </p>
          </a-popover>
        </div>
      </div>
    </div>
    <div
      v-else
      style="
        top: 0;
        position: fixed;
        width: 100%;
        background: #fff;
        z-index: 99;
      "
    >
      <div class="header-m">
        <div class="left">
          <div class="left-name" @click="look('./index')">
            <img
              src="https://www.xuezhiqiao.com/images/cgicon.png"
              width="50px"
            />
          </div>
          <!-- <div class="header-top">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="" @click="look('./index')"> 首页 </a-menu-item>
            <a-menu-item key="about" @click="look('./aboutN')">
              关于
            </a-menu-item>
            <a-menu-item key="result" @click="look('./result', true)">
              成绩查询
            </a-menu-item>

            <a-menu-item key="enroll" @click="look('./enroll', true)">
              我的报名
            </a-menu-item>
            <a-menu-item key="selfDetail" @click="look('./selfDetail', true)">
              个人信息
            </a-menu-item>
      
          </a-menu>
        </div>-->
        </div>
        <div class="header-right">
          <a-icon
            type="close"
            v-if="screenShow"
            @click="changeHeader"
            style="font-size: 24px"
          />
          <a-icon
            type="unordered-list"
            v-else
            @click="changeHeader"
            style="font-size: 24px"
          />

          <!-- <span style="margin-right: 30px; margin-top: 6px" @click="look('/')"
          >查看更多比赛</span
        >
        <a-button type="primary" v-if="!loginOk" @click="showDrawer">
          登录
        </a-button>

        <a-popover title="">
          <template slot="content">
            <p @click="out" style="cursor: pointer">退出登录</p>
          </template>
          <p
            v-if="loginOk"
            style="
              font-size: 16px;
              font-weight: bold;
              color: rgb(75 75 75);
              margin-top: 4px;
              margin-bottom: 0;
            "
          >
            欢迎回来！{{ user }}
          </p>
        </a-popover> -->
        </div>
      </div>
      <ul v-if="screenShow" class="m-ul">
        <li @click="look('./index', true)" class="active">首页</li>
        <!-- <li @click="look('./index', true)" class="active">个人信息</li> -->
        <li @click="look('./bank', true)" v-if="sfType=='家长'">关联账号管理</li>
        <li  @click="look('./classes', true)"  v-if="sfType=='老师'">班级管理</li>
        <li @click="look('./grade', true)" v-if="sfType=='学生'">我的班级</li>
    

        <!-- <li @click="look('/')">查看更多比赛</li> -->
        <li v-if="!loginOk">
          <a-button type="primary" @click="login"> 登录 </a-button>
        </li>
        <li v-if="loginOk">
          <p
            style="
              font-size: 16px;
              font-weight: bold;
              color: rgb(75 75 75);
              margin-top: 4px;
              margin-bottom: 0;
            "
          >
          {{sf}}  欢迎回来！{{ user }}
            <span @click="out" style="color: #3c90ee"> 【 退出登录 】</span>
          </p>
        </li>
      </ul>
    </div>
    <div class="header header-fixed">
      <div class="left">
        <div class="left-name"></div>
        <div class="header-top">
          <a-menu v-model="current" mode="horizontal">
            <a-menu-item key="adout" @click="look('./aboutN')"></a-menu-item>
            <a-menu-item key="schedule"></a-menu-item>
            <a-menu-item key="faq"></a-menu-item>
            <a-menu-item key="rules"></a-menu-item>
            <a-menu-item key="com"></a-menu-item>
            <a-menu-item key="arc"></a-menu-item>
          </a-menu>
        </div>
      </div>

      <div class="header-right"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      screenwidth: document.body.clientWidth,

      activeKey: null,
      current: [],
      name: "",
      visible: false,
      visibleIphon: false,
      loginOk: false,
      user: "",
      timer: null,
      screenShow: false,
      sf: "",
    };
  },
  props: {
    currentrStr: {
      type: Array,
      default: ["index"],
    },
    sfType:String
    // screenShow: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  watch: {
    currentrStr(newName, oldName) {
      console.log(oldName);
      this.current = newName;
      console.log(this.current);
    },
  },

  mounted() {
    console.log(this.currentrStr);
    this.current = this.currentrStr;
    window.onresize = () => {
      this.screenwidth = document.body.clientWidth;
    };

    if (
      window.localStorage.getItem("anjing-tokenName") &&
      window.localStorage.getItem("anjing-tokenName") != ""
    ) {
      this.user = window.localStorage.getItem("anjing-tokenName");
      this.loginOk = true;
      if (window.localStorage.getItem("anjing-token-group") == "teacher") {
      this.sf= '【 老师 】'
      } else if (
        window.localStorage.getItem("anjing-token-group") == "student"
       
      ) {
        this.sf= '【 学生 】'
      } else if (
        window.localStorage.getItem("anjing-token-group") == "parents"
      ) {
        this.sf= '【 家长 】'
      }
    }
    this.name = window.localStorage.getItem("name");
    // this.current.push(window.localStorage.getItem('anjing-to'));
    // console.log(  this.current)
    // if(window.localStorage.getItem('anjing-to') == 'start'){// 隐藏首页

    // }
  },
  methods: {
    look(URL, token = false, parm) {
      debugger;
      if (token) {
        if (
          window.localStorage.getItem("anjing-tokenName") &&
          window.localStorage.getItem("anjing-tokenName") != ""
        ) {
          // 需要校验
          if (URL == "./index") {
            if (
              window.localStorage.getItem("anjing-token-group") == "teacher"
            ) {
              this.$router.push({ path: "./ter" });
            } else if (
              window.localStorage.getItem("anjing-token-group") == "student"
            ) {
              this.$router.push({ path: "./list" });
            } else if (
              window.localStorage.getItem("anjing-token-group") == "parents"
            ) {
              this.$router.push({ path: "./pre" });
            }
          } else {
            this.$router.push({ path: URL });
          }
        } else {
          this.$message.info("您还未登录，请选择身份进行登录！");
          // this.$router.push({path:'./index'})
          // this.current = ['index']
          setTimeout(() => {
            location.reload();
          }, 2000);
        }
      } else {
        this.$router.push({ path: URL });
      }
    },
    handleClick(e) {
      console.log(e);
    },
    login() {
      this.$message.info("请选择身份，再进行登录！");
      // this.$router.push({
      //   path: "./login-reg-pass",
      //   query: { url: "./index" },
      // });
      this.$router.push({path:'./index'})
    },
    changeHeader() {
      this.screenShow = !this.screenShow;
    },
    out() {
      let data = {
        op: "logout",
      };
      let _this = this;
      this.$ajax
        .post(this.url + "/new_login.do", _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("登出");
              },
            });
            window.localStorage.setItem("anjing-tokenName", "");
            window.localStorage.setItem("anjing-token-group", "");
            _this.loginOk = false;
            _this.$router.push({ path: "./index" });
          } else {
            _this.$notification.open({
              message: "退出",
              duration: 2,
              description: response.data.msg,
              onClick: () => {
                console.log("退出");
              },
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
.m-ul {
  text-align: left;
  list-style: none;
  padding: 0;
  margin: 0;
}
.m-ul li {
  border-bottom: 1px solid #f0f0f0;
  padding: 8px 22px;
}
.header {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 3px 7px rgb(227 227 227);
  padding: 0 20px;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 9;
}
.header-fixed {
  position: inherit;
  box-shadow: none;
}
.left {
  display: flex;
  justify-content: left;
  line-height: 48px;
}
/deep/ .ant-menu-horizontal {
  border: none;
}
.left-name {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
}
.header-right {
  padding-top: 8px;
  cursor: pointer;
  color: #1e80ff;
  display: flex;
}
.active {
  background: #dff3fc;
}
.header-m {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 1px 3px 7px rgb(227 227 227);
}
.header {
  display: flex;
  justify-content: space-between;
  box-shadow: 1px 3px 7px rgb(227 227 227);
  padding: 0 20px;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 9;
}
.header-fixed {
  position: inherit;
  box-shadow: none;
}
.left {
  display: flex;
  justify-content: left;
  line-height: 48px;
}
/deep/ .ant-menu-horizontal {
  border: none;
}
.left-name {
  font-size: 20px;
  color: #000;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
}
.header-right {
  padding-top: 8px;
  cursor: pointer;
  color: #1e80ff;
  display: flex;
}
.active {
  background: #dff3fc;
}
.header-m {
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-shadow: 1px 3px 7px rgb(227 227 227);
}
</style>
